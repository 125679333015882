import { Component, Input, OnChanges } from '@angular/core';
import { ITableDataSource } from '@suvo-bi-lib';
import {
  circleMarker,
  featureGroup,
  LatLngBounds,
  latLng,
  Layer,
  FitBoundsOptions,
  MapOptions,
  polyline,
  LatLng,
} from 'leaflet';
import { type ExportVesselTrackResult, MarineTrafficApiService } from '../../../marine-traffic';
import { IShip } from '../../../ships/interface/ship.interface';
import { ShipsService } from '../../../ships/service/ships.service';
import { BASE_LAYERS } from '../../constants/map-constants';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { ITowLogEvent } from '../../interface/tow-log-event.interface';

@Component({
  selector: 'app-tow-log-map-tile',
  template: `
    <div
      [style.height]="height"
      leaflet
      [leafletOptions]="mapOptions"
      [leafletBaseLayers]="baseLayers"
      [leafletLayers]="layers"
      [leafletFitBounds]="fitBounds"
      [leafletFitBoundsOptions]="fitBoundsOptions"
    >
      @if (canUseAIS) {
        <div class="ais-btn">
          <button mat-raised-button (click)="loadMarineTrafficData()">Show AIS</button>
        </div>
      }
    </div>
  `,
  styles: [
    `
      .ais-btn {
        position: absolute;
        top: 12px;
        width: 100%;
        z-index: 9999;
        display: flex;
        justify-content: center;
      }
    `,
  ],
})
export class TowLogMapTileComponent implements OnChanges {
  @Input() tow: IRecordingEvent<'tow'>;
  @Input() towLog: ITableDataSource<ITowLogEvent>;
  @Input() height: string;

  constructor(
    private readonly marineTraffic: MarineTrafficApiService,
    private readonly shipService: ShipsService,
  ) {}

  readonly mapOptions: MapOptions = {
    center: latLng(50.37, -4.14),
    zoom: 10,
  };

  readonly fitBoundsOptions: FitBoundsOptions = {
    padding: [80, 80],
  };

  readonly markers = featureGroup();

  readonly layers: Layer[] = [this.markers];

  readonly baseLayers = BASE_LAYERS;

  enableMarkers = {
    cpr: true,
    ais: false,
  };

  fitBounds: LatLngBounds;
  canUseAIS = false;
  ais: ExportVesselTrackResult;

  ship: IShip;

  async ngOnChanges() {
    if (!this.tow?.metadata?.shipId) {
      this.ship = undefined;
    } else if (this.tow.metadata.shipId !== this.ship?._id) {
      this.ship = await this.shipService.getOne('', this.tow.metadata.shipId);
    }

    // this.canUseAIS = this.ship?.inAisFleet;
    this.canUseAIS = true;

    this.generateMarkers();
  }

  private generateMarkers() {
    this.markers.clearLayers();
    const coords: LatLng[] = [];
    for (const event of this.towLog.data.sort(
      (a, b) => Date.parse(a.eventDate as never) - Date.parse(b.eventDate as never),
    )) {
      if (event.eventLatitude && event.eventLongitude) {
        const coord = latLng(event.eventLatitude, event.eventLongitude);
        coords.push(coord);
      }
    }
    if (coords.length) {
      this.markers.addLayer(
        polyline(coords, {
          color: 'var(--primary-color)',
          weight: 4,
        }),
      );
      this.markers.addLayer(
        circleMarker(coords[0], {
          color: 'var(--primary-color)',
          radius: 4,
          weight: 4,
          fillOpacity: 1,
          fillColor: '#0f0',
        }),
      );
      if (coords.length >= 2) {
        this.markers.addLayer(
          circleMarker(coords[coords.length - 1], {
            color: 'var(--primary-color)',
            radius: 4,
            weight: 4,
            fillOpacity: 1,
            fillColor: '#a04',
          }),
        );
      }

      this.fitBounds = this.markers.getBounds();
    }
    this.generateAisMarkers();
  }

  private generateAisMarkers() {
    if (this.ais) {
      const coords: LatLng[] = [];
      for (const position of this.ais) {
        const coord = latLng(parseFloat(position[4]), parseFloat(position[3]));
        coords.push(coord);
      }
      if (coords.length) {
        this.markers.addLayer(
          polyline(coords, {
            color: 'rgb(28, 101, 217)',
            weight: 4,
          }),
        );

        this.markers.addLayer(
          circleMarker(coords[0], {
            color: 'rgb(28, 101, 217)',
            radius: 4,
            weight: 4,
            fillOpacity: 1,
            fillColor: '#0f0',
          }),
        );
        if (coords.length >= 2) {
          this.markers.addLayer(
            circleMarker(coords[coords.length - 1], {
              color: 'rgb(28, 101, 217)',
              radius: 4,
              weight: 4,
              fillOpacity: 1,
              fillColor: '#a04',
            }),
          );
        }

        this.fitBounds = this.markers.getBounds();
      }
    }
  }

  getDateString(date: Date) {
    const pad = (n: number) => n.toString().padStart(2, '0');
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}`;
  }

  async loadMarineTrafficData() {
    let todate: number, fromdate: number;
    for (const event of this.towLog.data) {
      const ms = Date.parse(event.eventDate as never);
      if (!todate || ms > todate) {
        todate = ms;
      }
      if (!fromdate || ms < fromdate) {
        fromdate = ms;
      }
    }

    this.ais = await this.marineTraffic.vesselTrack({
      imo: this.ship.imoNumber,
      fromdate: this.getDateString(new Date(fromdate)),
      todate: this.getDateString(new Date(todate)),
      protocol: 'json',
    });

    this.generateAisMarkers();
    this.canUseAIS = false;
  }
}
