<app-header />

<mat-card style="margin: 0 var(--global-padding)">
  <mat-card-header>
    <mat-card-title>Tow Details</mat-card-title>
    <mat-card-subtitle style="opacity: 60%">Edit the key details of the tow</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-edit
      [permission]="tow?.metadata?.status === 'finalised' ? null : 'recordingEvent.edit'"
      [formGroup]="formGroup"
      (save)="onSave($event)"
      [errors]="serverErrors"
      #editComponent
    >
      <app-tow-id-form-control [towId]="formGroup.get('oldTowBaseId')" />

      <div class="input-group">
        <mat-form-field>
          <mat-label>Route Status</mat-label>
          <input matInput formControlName="routeStatus" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Tow Status</mat-label>
          <mat-select formControlName="status">
            @if (tow?.metadata.status === 'finalised') {
              <mat-option value="finalised">
                <app-status-chip value="finalised" />
              </mat-option>
            } @else {
              @for (option of statusOptions; track $index) {
                <mat-option [value]="option">
                  <app-status-chip [value]="option" />
                </mat-option>
              }
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Validity</mat-label>
          <mat-select formControlName="validity">
            @for (option of validityOptions; track $index) {
              <mat-option [value]="option">{{ option }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Prop Setting</mat-label>
          <input matInput type="number" formControlName="propSetting" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Month</mat-label>
          <mat-select formControlName="month">
            <mat-option [value]="1">January</mat-option>
            <mat-option [value]="2">February</mat-option>
            <mat-option [value]="3">March</mat-option>
            <mat-option [value]="4">April</mat-option>
            <mat-option [value]="5">May</mat-option>
            <mat-option [value]="6">June</mat-option>
            <mat-option [value]="7">July</mat-option>
            <mat-option [value]="8">August</mat-option>
            <mat-option [value]="9">September</mat-option>
            <mat-option [value]="10">October</mat-option>
            <mat-option [value]="11">November</mat-option>
            <mat-option [value]="12">December</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Year</mat-label>
          <input matInput type="number" formControlName="year" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <suvo-bi-reference-autocomplete-selector
          referenceLabelProperty="oldCprId"
          entityDefinitionAlias="cprs"
          formControlName="cprId"
          fxFlex="50%"
        />

        <mat-form-field>
          <mat-label>Internal ID</mat-label>
          <input matInput pattern="^\d+-\d+(\/\d+)?$" formControlName="internalId" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <suvo-bi-reference-autocomplete-selector
          referenceLabelProperty="label"
          entityDefinitionAlias="ships"
          formControlName="shipId"
        />
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Silk Reading Start</mat-label>
          <input
            matInput
            type="number"
            min="0"
            max="999.9"
            step="0.1"
            formControlName="silkReadingStart"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Silk Reading End</mat-label>
          <input
            matInput
            type="number"
            min="0.1"
            max="999.9"
            step="0.1"
            formControlName="silkReadingEnd"
          />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="message"></textarea>
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Instrument ID</mat-label>
          <input matInput formControlName="instrumentId" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Instrument Data File</mat-label>
          <input matInput formControlName="instrumentDataFile" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Serial Number Per Calendar Month</mat-label>
          <input matInput formControlName="serialNumberPerCalendarMonth" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Prop Setting</mat-label>
          <input matInput formControlName="propSetting" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>From Port Name</mat-label>
          <input matInput formControlName="fromPortName" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>To Port Name</mat-label>
          <input matInput formControlName="toPortName" />
        </mat-form-field>
      </div>
    </app-edit>
  </mat-card-content>
</mat-card>
