import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '$env';
import type * as T from '../interfaces/vessel-track';

@Injectable({ providedIn: 'root' })
export class MarineTrafficApiService {
  private readonly http = inject(HttpClient);

  vesselTrack(options: T.ExportVesselTrackOptions) {
    return lastValueFrom(
      this.http.post<T.ExportVesselTrackResult>(
        `${environment.cprServiceApi}marine-traffic/vessel-track`,
        options,
      ),
    );
  }
}
